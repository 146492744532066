import imgserv from "../../media/headimg.52bab7066d4da8fab79d.png"
import "./Serv.css" ;
const Serv = () => {
  return (
    <div className="container mt-4">
      <div className="title-serveces">
        <img src={imgserv} />
        <h2>خدماتنا</h2>
      </div>
    </div>
  );
};

export default Serv ;
